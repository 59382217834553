import { CompanyEvaluationModuleType, CompanyInfoSectionGroupKeys, ResultsModuleType } from '@/queries/graphql-types';
import { EvaluationSectionGroups } from '@/types/company';

export const PRODUCT_PATHNAMES = ['/stock', '/app/start', '/index-builder', '/analyst'];

export const AVAILABLE_PATHNAMES_FOR_RESEARCH = ['/stock', '/index/', '/analyst'];

export type EvaluationSectionGroup = Record<
    EvaluationSectionGroups,
    {
        id: string;
        title: string;
    }
>;
const evaluationSectionGroupsData: EvaluationSectionGroup = {
    [EvaluationSectionGroups.COMPANY]: {
        id: EvaluationSectionGroups.COMPANY,
        title: 'Company',
    },
    [EvaluationSectionGroups.COMPARISONS]: {
        id: EvaluationSectionGroups.COMPARISONS,
        title: 'Comparisons',
    },
};

export const COMPANY_INFO_MODULES_LIST = {
    [CompanyInfoSectionGroupKeys.CompanyPerformance]: {
        id: CompanyInfoSectionGroupKeys.CompanyPerformance,
        title: 'Company performance',
    },
    [CompanyInfoSectionGroupKeys.Description]: {
        id: CompanyInfoSectionGroupKeys.Description,
        title: 'Description',
    },
    [CompanyInfoSectionGroupKeys.Metrics]: {
        id: CompanyInfoSectionGroupKeys.Metrics,
        title: 'Metrics',
    },
    [CompanyInfoSectionGroupKeys.Documents]: {
        id: CompanyInfoSectionGroupKeys.Documents,
        title: 'Documents',
    },
};

export const COMPANY_EVALUATION_MODULES_LIST = {
    [CompanyEvaluationModuleType.KeyTakeaways]: {
        id: CompanyEvaluationModuleType.KeyTakeaways,
        title: 'Key takeaways',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.EvaluationSummary]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.EvaluationSummary,
        title: 'Evaluation summary',
    },
    [CompanyEvaluationModuleType.PriceHistory]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.PriceHistory,
        title: 'Price history',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.Fundamentals]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.Fundamentals,
        title: 'Fundamentals',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.FinancialStatements]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.FinancialStatements,
        title: 'Financial statements',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.Filings]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.Filings,
        title: 'Public filings',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.EarningsCall]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.EarningsCall,
        title: 'Earnings calls',
    },
    [CompanyEvaluationModuleType.News]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPANY],
        id: CompanyEvaluationModuleType.News,
        title: 'News evaluation',
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [CompanyEvaluationModuleType.ComparativeAnalysis]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPARISONS],
        id: CompanyEvaluationModuleType.ComparativeAnalysis,
        title: 'Comparative analysis',
    },
    [CompanyEvaluationModuleType.PeerFundamentals]: {
        group: evaluationSectionGroupsData[EvaluationSectionGroups.COMPARISONS],
        id: CompanyEvaluationModuleType.PeerFundamentals,
        title: 'Comparison metrics',
    },
};

const ANALYSIS_RESULTS_MODULES_LIST = {
    [ResultsModuleType.Analysis]: {
        id: ResultsModuleType.Analysis,
        title: 'Analysis',
    },
    [ResultsModuleType.DeepDive]: {
        id: ResultsModuleType.DeepDive,
        title: 'Deep dive',
    },
    [ResultsModuleType.StrongMatches]: {
        id: ResultsModuleType.StrongMatches,
        title: 'Strong matches',
    },
    [ResultsModuleType.Subcategories]: {
        id: ResultsModuleType.Subcategories,
        title: 'Subcategories',
    },
    [ResultsModuleType.Filters]: {
        id: ResultsModuleType.Filters,
        title: 'Filters',
    },
};

export const FULL_MODULES_LIST: {
    [key in ResultsModuleType | CompanyEvaluationModuleType | CompanyInfoSectionGroupKeys]?: {
        id: string;
        title: string;
        group?: {
            id: string;
            title: string;
        };
    };
} = {
    ...COMPANY_INFO_MODULES_LIST,
    ...COMPANY_EVALUATION_MODULES_LIST,
    ...ANALYSIS_RESULTS_MODULES_LIST,
};
